export const concreteServices = {
  parkingInfrastructure: {
    title: 'Parking Infrastructures',
    desc: 'C&C Industrial has worked on many parking deck projects over the years. As growth continues at a rapid rate in the markets we serve C&C Industrial helps to assist in optimizing the parking infrastructures schedules through planning and communication. C&C Industrial has the expertise to complete your Parking Infrastructures on schedule and on budget. ',
    bullets: [
      'Walls',
      'Foundations',
      'Pits',
      'Washes',
      'Podium',
      'PT',
      'Specialty formwork',
      'Flatwork/Paving',
      'SOG'
    ],
    images: [
      {
        uuid: 'e63f0ed1-db16-41c1-913c-aeb798aa6dd2',
        alt: 'C&C Industrial Parking Infrastructure and Boom Pump'
      },
      {
        uuid: 'f0adb21e-370c-497e-8016-0b5e88c14b4b',
        alt: 'Parking Deck'
      },
      {
        uuid: '5d9ce548-d5cf-4736-a699-6d575f815601',
        alt: 'Parking Deck'
      },
      {
        uuid: '2a884c21-2da4-45a3-91f3-b20d5c4bb19f',
        alt: 'The Elliot Parking - Chapel Hill, NC'
      }
    ]
  },
  commercial: {
    title: 'Commercial',
    desc: 'C&C Industrial is set up to be an asset for your commercial project needs. From Mid-rise podiums, parking decks, Hospitality, Mixed Use, or tilt/DC centers C&C Industrial has the experience needed to complete the project on schedule and on budget. With 26 years of experience C&C has completed projects with a variety of FF/FL requirements, specialty shoring requirements, and assisted our clients in achieving a quality product to deliver to the owners they work with.',
    bullets: [
      'Podium',
      'PT',
      'Foundations',
      'Columns',
      'SOG',
      'SOMD',
      'CIP Walls',
      'Pits',
      'Specialty formwork',
      'Flatwork/Paving'
    ],
    images: [
      {
        uuid: '08b70eec-60b6-4d69-84f9-db04b885447b',
        alt: 'Glenn Lakes VII Raleigh, NC'
      },
      {
        uuid: '51f63c5c-d4b5-4735-8996-23f879de52cb',
        alt: 'The Current Office, Richmond, VA'
      },
      {
        uuid: '34742cd6-ce02-4236-b979-2e297ec5e5d2',
        alt: 'South Falls Tower, Richmond, VA'
      },
      {
        uuid: '9f241df6-1dfa-4e81-a04d-2054d4674210',
        alt: 'Pier 33 Wilmington, NC'
      },
      {
        uuid: 'c6625888-37d2-4d09-8a7c-38430fd0503d',
        alt: 'Block 9 VA'
      },
      {
        uuid: '5c617117-ee2e-4574-afd2-4bf093a8b404',
        alt: 'Block 9 VA'
      },
      {
        uuid: 'd9cade94-0a47-41f4-8d39-3faca2f015b5',
        alt: 'Tower Foundation'
      },
      {
        uuid: '604ffd17-7221-48e8-8ada-3e5c38a91b9a',
        alt: 'Hotel Norfolk, VA'
      },
      {
        uuid: '2c858fa5-8bfb-43aa-b88d-c2260b71f6d0',
        alt: 'Va Beach, VA'
      },
      {
        uuid: '10caa984-0258-41d2-8188-580309a62afa',
        alt: 'Podium Slab/Columns/Shafts'
      },
      {
        uuid: '34dd3165-3500-435e-9df7-9b5b37089165',
        alt: 'Podium Slab'
      },
      {
        uuid: '40faaba7-ff42-49ee-acfa-3a2f65a7839c',
        alt: 'Theatre Podium Va, Beach'
      }
    ]
  },
  civil: {
    title: 'Civil - Concrete',
    desc: 'C&C Industrial has been involved in numerous civil projects over the years and have the capabilities to be flexible and meet these projects safety and schedule requirements.',
    bullets: [
      'WWTP',
      'Airport',
      'Specialty form work',
      'Pits',
      'CIP Walls',
      'Foundation',
      'Columns',
      'SOG',
      'SOMD',
      'Flatwork/Paving'
    ],
    images: [
      {
        uuid: 'cab29d2b-92e7-4d1b-a154-217290f40cd6',
        alt: 'Clarifier Tank Wall Wilmington, NC'
      },
      {
        uuid: 'f798eebd-c2a7-4fd3-911d-191ae9fc7ee3',
        alt: 'Clarifier Tank Addition, Ahoskie, NC'
      },
      {
        uuid: '48f0b01a-b18c-4f52-b197-6af94ca8932e',
        alt: 'Holding Tanks Roanoke Rapids, NC'
      },
      {
        uuid: '56e6238b-2580-4d86-b223-33f9ec902612',
        alt: 'Waste Water Treatment Tank'
      }
    ]
  },
  healthcareEducational: {
    title: 'Healthcare/Educational',
    desc: 'We have experience working with both public and private Institutions on a variety of projects over the past 26 years. We understand the time restraints these projects have and ensure our Preconstruction and Project Management teams put a plan of action together to ensure a project’s success for all our clients.',
    bullets: [
      'Hospital',
      'Research Labs',
      'Biopharmaceutical',
      'Nursing homes',
      'Private Universities',
      'Public Universities',
      'Athletic Facilities',
      'Dormitories',
      'Mixed Use',
      'Foundation',
      'Columns',
      'SOG',
      'Podium',
      'PT',
      'SOMD',
      'Specialty Formwork',
      'Pits'
    ],
    images: [
      {
        uuid: '06aaa4f2-9580-4a5c-adc3-10e2422da3a5',
        alt: 'Greenville, NC'
      },
      {
        uuid: '901d22a1-1b18-4326-bcda-850504127316',
        alt: 'PT Richmond, VA'
      },
      {
        uuid: '69079bbc-3e48-444f-8ef6-2edb84c96d13',
        alt: 'Pier 33 Wilmington, NC'
      },
      {
        uuid: '7eb2b922-39c4-4474-9ca0-4c2f96c6342f',
        alt: 'Pier 33 Wilmington, NC'
      },
      {
        uuid: '5312021a-e464-48bc-a76a-0f15d14190b7',
        alt: 'Block 9'
      },
      {
        uuid: '3934622d-d152-4b6a-a273-7de094384dd0',
        alt: 'Va Beach, VA'
      },
      {
        uuid: '8ccd950a-b852-44fa-ba71-143d0751dbd1',
        alt: 'New Hanover Medical, Wilmington, NC'
      },
      {
        uuid: '5bc97e1e-4562-4b4a-a7ba-847aa5e44b26',
        alt: 'UNC, Chapel Hill, NC'
      },
      {
        uuid: 'b416e8fd-a8ec-4c34-9a63-f315971e6d40',
        alt: 'Curtis Media UNC, Chapel Hill, NC'
      },
      {
        uuid: 'd10e1bca-e966-4454-9ec8-f699136ccb51',
        alt: 'Creek Town Middle School - Winnabow, NC'
      },
      {
        uuid: 'c3db37a1-cb13-4d19-a841-7e5e94ecb7ae',
        alt: 'East Carolina University Dowdy-Ficklen Stadium'
      }
    ]
  },
  industrial: {
    title: 'Industrial',
    desc: 'C&C Industrial has the experience and manpower required to achieve the tightest of project schedules for a variety of industrial projects. Our company has the equipment in house to achieve large pours needed to push the schedule and maintain the high FF/FL tolerances required on many projects. Our professional approach to planning and limiting scope gap allows us to provide our customers a valued service without sacrificing quality of work.',
    bullets: [
      'Foundations',
      'SOG',
      'SOMD',
      'Pits',
      'CIP Walls',
      'PT',
      'Tilt',
      'Flatwork/Paving'
    ],
    images: [
      {
        uuid: 'ee142958-7476-43ce-a21b-e9635fc70eda',
        alt: 'Chester, VA'
      },
      {
        uuid: 'b59bd64b-9c3f-44c5-9bfa-8d3bd8114e83',
        alt: 'Tilt Durham, NC'
      },
      {
        uuid: '704c00f6-f19a-4d66-8d6e-7809d2d81855',
        alt: 'Richmond, VA'
      },
      {
        uuid: '7d631415-127c-43e5-9a0e-02a83fc91561',
        alt: 'Fayetteville, NC'
      },
      // { // Removed 2021-02-28 per Kevin
      //   uuid: 'bd808b83-9767-43e3-8b39-260a3e73db03',
      //   alt: 'Emporium, VA'
      // },
      {
        uuid: 'df90d419-266e-4957-9aea-c71021e126a8',
        alt: 'Emporia, VA'
      },
      {
        uuid: 'c10f9ccc-8e26-4598-9f53-33cc070d9557',
        alt: 'Linamar Forge - Wilson, NC'
      },
      {
        uuid: '5bb2a308-08d0-4967-aadc-6d058ad37d4f',
        alt: 'Linamar Forge - Wilson, NC'
      },
      {
        uuid: '5bb2f367-8592-45cb-9706-8c73f77c5f40',
        alt: 'Pit Install - Sanford, NC'
      }
    ]
  },
  multiFamilResidential: {
    title: 'Multi-Family Residential',
    desc: 'At C&C Industrial we have experience in a variety of formwork systems allowing us to put together an aggressive schedule to meet our client and the owners needs. We have completed numerous projects in some of the fastest growing markets in the Southeast. Our planning process allows us to offer cost saving options, aggressive project schedules, and delivery of an end product our General Contractors and Owners can be proud of. <br/><br/>C&C Industrial works to ensure that the owners are able to generate a ROI on their properties in a timely manner.',
    bullets: [
      'Foundations',
      'Columns',
      'SOG',
      'Podium',
      'CIP Walls',
      'SOMD',
      'Pits',
      'Flatwork / Paving'
    ],
    images: [
      {
        uuid: '66c160de-0893-4d5b-8f97-1d6d8e59809e',
        alt: 'The Current, Richmond, VA'
      },
      {
        uuid: '1c701445-5894-4b3f-91a9-3a41a7c4df38',
        alt: 'Richmond, VA'
      },
      {
        uuid: '24d62d6b-4249-462f-be5f-4b4f6d7e445e',
        alt: 'Jolly Roger Greenville, NC'
      },
      {
        uuid: '2f52ec0a-bf60-4993-b2d2-49cfb3fbd19b',
        alt: 'Jolly Roger Greenville, NC'
      },
      {
        uuid: '0eb0f24f-5c1c-49ee-b86a-52678fde3653',
        alt: 'Wilmington, NC'
      },
      {
        uuid: '60c7a3db-837f-4b7b-bb0a-fbbcaba9bf4a',
        alt: 'BirchTree Villas, Chapel Hill'
      },
      {
        uuid: '87f0e789-b69a-4438-bbc3-5e8f92f96723',
        alt: 'The Current Richmond, VA'
      },
      {
        uuid: '9bb0fc18-52f8-443b-9f53-f38e2675c182',
        alt: 'Pier 33 Wilmington, NC'
      },
      {
        uuid: 'fc9765c3-ba66-43d4-a155-d0a015c8051c',
        alt: '400 Hull St Richmond, VA'
      },
      {
        uuid: 'c515884d-d6e8-4625-8868-7975fd42857e',
        alt: '400 Hull St Richmond, VA'
      },
      {
        uuid: '522d85e8-4d55-4d71-9cb0-fc5f6a93668f',
        alt: 'Embassy Suites Wilmington , NC'
      },
      {
        uuid: '0d6b5e42-8f1d-4556-9394-3c659da30bcf',
        alt: 'Embassy Suites Wilmington , NC'
      },
      {
        uuid: '6e744ee4-92bf-463f-82e0-7a1e6cf903e3',
        alt: 'Chesapeake, VA'
      },
      {
        uuid: 'ecc46d51-7546-4749-ba5c-dbb4138e2562',
        alt: 'Block 9'
      },
      {
        uuid: '0dc51206-53e6-4ec1-9e4e-a83ee8bdde68',
        alt: 'Block 9'
      },
      {
        uuid: '859db65e-6c60-44cd-8b60-b1916237889b',
        alt: 'Crown @ 501 - Durham, NC'
      }
    ]
  },
  concreteDemo: {
    title: 'Concrete Demo',
    desc: 'C&C Industrial has the capabilities to provide Flat/Slab sawing, wall sawing, core drilling, and some structural Demolition for our clients. We provided our clients with a professional service stressing accuracy and safety on each of our projects.',
    bullets: [
      'Paving',
      'Industrial',
      'Pharmaceutical',
      'Hospitals',
      'Commercial'
    ],
    images: [
      {
        uuid: '6609540d-45cd-4da7-8cbd-cf5e31291064',
        alt: 'Concrete Demo'
      },
      {
        uuid: '0a6facad-4b9d-4cea-96e0-d7a8df5e72b0',
        alt: 'Concrete Demo'
      },
      {
        uuid: 'd7b855b4-e4e8-4af8-9635-d57dd7444f6b',
        alt: 'Concrete Demo'
      },
      {
        uuid: 'fc17a652-2a13-40e1-880d-a36a19d08172',
        alt: 'Concrete Demo'
      }
    ]
  },
  concretePaving: {
    title: 'Concrete Paving',
    desc: 'C&C Industrial owns multiple screed machines that allow us to pour large paving jobs quickly and efficiently. Our screeds are capable of 2D & 3D paving allowing us to meet all your project needs. C&C Industrial is capable of meeting some of the industries strictest standards while delivering a finished product our clients of come to expect.',
    bullets: [
      'Roller Compacted Concrete Paving',
      'Compacted Concrete Paving',
      'Jointed Concrete Paving',
      'Continuous Reinforcing Paving'
    ],
    images: [
      {
        uuid: 'ee142958-7476-43ce-a21b-e9635fc70eda',
        alt: 'FedEx Freight - Chester, VA'
      },
      {
        uuid: '7c6acce7-f909-4d12-8276-0afe4dbba448',
        alt: 'FedEx Ground - Ashland, VA'
      },
      {
        uuid: '0d48563b-f627-40b4-936a-acf80c9e003d',
        alt: 'FedEx Freight - Chester, VA'
      },
      {
        uuid: '3f3e44e2-0e8a-40c5-8406-f7d31de2b9a7',
        alt: 'Paving - Norfolk, VA'
      },
      {
        uuid: '9bbfa2cb-9539-4a77-a128-72ae64eef9f4',
        alt: 'Carolina Egg - Nashville, NC'
      }
    ]
  },
  miscSpecialty: {
    title: 'Misc/Specialty',
    desc: `C & C Industrial has completed projects work in the energy industry and pharmaceutical industry over our ${new Date().getFullYear() - 1992} year history. We have the capability to complete demo work, offer pumping / telebelt services, provide concrete washout services, and complete large paving projects.`,
    bullets: [
      'Demo work',
      'Pumping / Telebelt Services',
      'Concrete Washout Services',
      'Large paving projects'
    ],
    images: [
      {
        uuid: '5726f801-31dc-4109-a2cf-6d1e612661f2',
        alt: 'Pharmaceutical Trench Job, NC'
      },
      {
        uuid: '023043cf-e40c-4343-93bc-9539a71cd1cb',
        alt: 'Hubell Rocky Mount, NC'
      },
      {
        uuid: '7fbc4578-7f9c-4101-bac3-6b830914f2ec',
        alt: 'North Waterfront Park Wilmington, NC'
      },
      {
        uuid: 'a08d9f2d-c9eb-45f0-8ea5-7ab2eba40360',
        alt: 'Wilmington, NC'
      },
      {
        uuid: 'bfec7c73-b4c1-4c9f-a551-7b406b20b7ea',
        alt: 'Wilmington, NC'
      },
      {
        uuid: '42d8c2c0-ce95-468f-b296-521b94fb6fc6',
        alt: 'North Waterfront Park Wilmington, NC'
      },
      {
        uuid: 'aa514d47-e83e-427c-9470-20d02659b13b',
        alt: 'Richmond, Va'
      },
      {
        uuid: 'ed94b551-3315-41c8-8ef5-ec7f48835c9d',
        alt: 'Theatre Seating Va Beach, VA'
      }
    ]
  }
}
