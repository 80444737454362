<template>
  <div class="grey darken-4">
    <BannerHeader
      :title="title"
      subtitle="Building Foundations and Relationships that stand the test of time."
      uuid="235d22c3-8194-4f27-bd97-ee32f09b2e7c"
    />
    <ServicesContent
      :bullets="bullets"
      class="mt-12"
      :content="content"
      :images="images"
      :title="title"
    >
      <v-container fluid>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="7"
            lg="6"
            class="grey--text text--lighten-1"
          >
            <h1 class="text-h4 white--text mb-2 font-weight-bold">C&amp;C Industrial, Inc</h1>
            <v-divider class="primary pt-1 mb-3"/>
            <p>
              C&C Industrial is your trusted industry expert for your concrete services needs. We are experts in Parking Infrastructures, Commercial projects, Civil Concrete jobs, the Healthcare/Educational Industry, Industrial work, as well as many other areas where an experienced Division 3 contractor is the difference in project success or project failure.
            </p>
            <p>
              At C&C Industrial we help our clients achieve their goals of delivering a job on schedule and on budget. We can work on projects with extreme logistical concerns to projects that run simultaneously together on the same jobsite.
            </p>
            <p>
              Since 1992 we have expanded our services to meet our clients needs and continue to grow and expand into new markets today.
            </p>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="5"
            lg="4"
          >
           <img
              data-blink-uuid="60a843ba-96d0-4d97-90b2-ea35954cd486"
              width="100%"
              height="auto"
            />
          </v-col>
        </v-row>
      </v-container>
    </ServicesContent>
    <v-divider />
  </div>
</template>

<script>
import BannerHeader from '@/core/components/BannerHeader'
import ServicesContent from '../components/ServicesContent'
import { concreteServices } from '../constants'
export default {
  metaInfo: {
    title: 'Concrete Services',
    description: 'C&C Industrial is your trusted industry expert for your concrete services needs. We are experts in Parking Infrastructures, Commercial projects, Civil Concrete jobs, the Healthcare/Educational Industry, Industrial work, as well as many other areas where an experienced Division 3 contractor is the difference in project success or project failure.'
  },
  components: {
    BannerHeader,
    ServicesContent

  },
  data () {
    return {
      title: 'Concrete Services',
      content: 'ConcreteServicesContent',
      images: []
    }
  },
  computed: {
    bullets () {
      const bullets = []
      for (const [key, value] of Object.entries(concreteServices)) {
        bullets.push({
          title: value.title,
          key
        })
      }
      return bullets
    }
  }
}
</script>
